/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"kompletace osvetleni"}>
        <Column className="pb--30 pt--30" name={"g97rpkrf2gs"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"Kompletace osvětlení v RD"}>
              </Title>

              <Text className="text-box fs--20 w--500 lh--14" content={"Kompletace svítidel v rodinném domě. Kruhové led svítidla v kombinaci s led osvětlením se čtvercovým rámečkem. \n"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--20" name={"l3wsj3yiofo"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/205dfbb5b00042f7b4f93dd10970de18_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/205dfbb5b00042f7b4f93dd10970de18_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/205dfbb5b00042f7b4f93dd10970de18_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/205dfbb5b00042f7b4f93dd10970de18_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/205dfbb5b00042f7b4f93dd10970de18_s=1400x_.jpg 1400w"} position={{"x":"0%","y":"0%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/5aeaf296288c49a38f0be442155cd3de_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/5aeaf296288c49a38f0be442155cd3de_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/5aeaf296288c49a38f0be442155cd3de_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/5aeaf296288c49a38f0be442155cd3de_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/5aeaf296288c49a38f0be442155cd3de_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/0ba3869e0cd040a589cf5e77fac7215f_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/0ba3869e0cd040a589cf5e77fac7215f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/0ba3869e0cd040a589cf5e77fac7215f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/0ba3869e0cd040a589cf5e77fac7215f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/0ba3869e0cd040a589cf5e77fac7215f_s=1400x_.jpg 1400w"} position={{"x":"-12.261096605744125%","y":"-50.4490861618799%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/1784e431381c40c0afdd9415b1a3c4f7_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/1784e431381c40c0afdd9415b1a3c4f7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/1784e431381c40c0afdd9415b1a3c4f7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/1784e431381c40c0afdd9415b1a3c4f7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/1784e431381c40c0afdd9415b1a3c4f7_s=1400x_.jpg 1400w"} position={{"x":"-2.3054830287206265%","y":"-82.37597911227155%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/382cc614cee14cfb83fe2d99c81a731e_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/382cc614cee14cfb83fe2d99c81a731e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/382cc614cee14cfb83fe2d99c81a731e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/382cc614cee14cfb83fe2d99c81a731e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/382cc614cee14cfb83fe2d99c81a731e_s=1400x_.jpg 1400w"} position={{"x":"0%","y":"-32.02088772845953%"}}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/11759/d0002300361e454ba5c83b198083225d_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/11759/d0002300361e454ba5c83b198083225d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11759/d0002300361e454ba5c83b198083225d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11759/d0002300361e454ba5c83b198083225d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11759/d0002300361e454ba5c83b198083225d_s=1400x_.jpg 1400w"} position={{"x":"0%","y":"-69.177545691906%"}}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}